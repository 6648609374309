import smoothscroll from "smoothscroll-polyfill/dist/smoothscroll";
import ScrollCarousel from "./components/scroll-snap-carousel/ScrollCarousel.js";
import { throttle } from "throttle-debounce";
import { initCollapsibles } from "./components/collapsibles.js";
// import * as Turbo from "@hotwired/turbo";

const docEl = document.documentElement;

window.addEventListener("DOMContentLoaded", (event) => {
  initScripts();
});

window.addEventListener("load", (event) => {
  const collapsibles = document.querySelector(".profile-team");
  if (collapsibles) {
    initCollapsibles(collapsibles);
  }
});

function initScripts() {
  const carousels = document.querySelectorAll("[data-carousel]");
  const homeProjectLinks = document.querySelectorAll(
    ".tpl-home .projects-categories a"
  );

  if (carousels) {
    carousels.forEach((carousel) => new ScrollCarousel(carousel).init());
  }

  // homeProjectLinks.forEach((link) => {
  //   link.addEventListener("click", (e) => e.preventDefault());
  // });
}

smoothscroll.polyfill();
