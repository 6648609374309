function initCollapsibles(node) {
  const els = node.querySelectorAll("li.has-meta");

  els.forEach((el) => {
    el.addEventListener("click", toggleVisible);
    el.dataset.vitaOffsetHeight = getAutoOffsetHeight(
      el.querySelector(".vita")
    );
  });
}

function toggleVisible(e) {
  const container = e.target.parentNode;
  const button = container.querySelector("h3");
  const vitaContainer = container.querySelector(".vita");
  const photo = container.querySelector(".vita-photo-inner");
  // console.log(e.target, vitaContainer);
  if (e.target !== button) {
    return;
  }

  if (typeof container.dataset.visible === "undefined") {
    container.dataset.visible = "";
    vitaContainer.style.height = container.dataset.vitaOffsetHeight + "px";

    if (photo) {
      setTimeout(() => {
        vitaContainer.style.overflow = "visible";
        photo.style.opacity = 1;
      }, 300);
    }
  } else {
    delete container.dataset.visible;

    if (photo) {
      photo.style.opacity = 0;
      setTimeout(() => {
        vitaContainer.style.overflow = "hidden";
        vitaContainer.style.height = 0;
      }, 300);
    } else {
      vitaContainer.style.overflow = "hidden";
      vitaContainer.style.height = 0;
    }
  }
}

function getAutoOffsetHeight(el) {
  let offSetHeight;
  const clone = el.cloneNode(true);
  clone.style.height = "auto";
  clone.style.overflow = "hidden";
  document.querySelector(".main-content").appendChild(clone);
  offSetHeight = clone.offsetHeight;
  clone.remove();
  return offSetHeight;
}

export { initCollapsibles };
